.quill {
  height: 100%;
  width: 100%;
}

.ql-toolbar.ql-snow {
  border-left: none;
  border-right: none;
}

.ql-container.ql-snow {
  border: none;
  overflow-x: hidden;
}

.ql-editor {
  white-space: normal;
  height: 100%;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

/* width */
.ql-editor::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.ql-editor::-webkit-scrollbar-track {
  background: rgb(0, 0, 0, 0);
  border-radius: 15px;
}

/* Handle */
.ql-editor::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background: hsl(217deg 7% 43%);
}

.quill-item {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  overflow: hidden;
}

.quill-item .ql-container {
  /* transform: scale(0.2); */
  overflow: hidden;
  width: 100%;
}

.quill-item .ql-editor {
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  user-select: none;
}

/* .quill-item .ql-container {
  transform: scale(0.6);
  width: 100%;
}
.quill-item .ql-container .ql-editor {
  transform: scale(0.6);
  width: 100%;
} */
