/* TODO Add site wide styles */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-text-1: 0deg 0% 100%;
    --color-text-2: 0deg 0% 0%;
    --color-primary-100: 211deg 88% 67%;
    --color-primary-200: 212deg 88% 71%;
    --color-primary-300: 213deg 89% 76%;
    --color-primary-400: 214deg 89% 79%;
    --color-primary-500: 215deg 88% 83%;
    --color-primary-600: 216deg 88% 87%;
    --color-surface-mixed-100: 215deg 24% 18%;
    --color-surface-mixed-200: 216deg 15% 26%;
    --color-surface-mixed-300: 217deg 10% 34%;
    --color-surface-mixed-400: 217deg 7% 43%;
    --color-surface-mixed-500: 219deg 6% 52%;
    --color-surface-mixed-600: 218deg 6% 61%;
  }
  :root[data-theme="light"] {
    /* --color-primary-100: 211deg 88% 67%; */
    /* --color-primary-100: 212deg 88% 71%; */
    /* --color-primary-100: 213deg 89% 76%; */
    /* --color-primary-100: 214deg 89% 79%; */
    /* --color-primary-100: 215deg 88% 83%; */
    --color-primary-100: 216deg 88% 87%;
    --color-text-1: 0deg 0% 0%;
    --color-text-2: 0deg 0% 0%;
    --color-hover: 0deg 0% 0%;
    --color-surface-mixed-100: 0deg 0% 95%;
    --color-surface-mixed-200: 209deg 100% 86%;
    --color-surface-mixed-300: 209deg 100% 100%;
    --color-surface-mixed-400: 209deg 100% 95%;
    --color-surface-mixed-500: 233deg 100% 92%;
    --color-surface-mixed-600: 210deg 8% 15%;
    --color-comp-1: 275deg 95% 92%;
    --color-comp-2: 209deg 95% 92%;
    --color-comp-3: 95deg 95% 92%;
    --color-comp-button-1: 40deg 95% 92%;
  }
  :root[data-theme="dark"] {
    /* --color-primary-100: 211deg 88% 67%; */
    /* --color-primary-100: 212deg 88% 71%; */
    /* --color-primary-100: 213deg 89% 76%; */
    /* --color-primary-100: 214deg 89% 79%; */
    /* --color-primary-100: 215deg 88% 83%; */
    --color-primary-100: 216deg 88% 87%;
    --color-text-1: 0deg 0% 100%;
    --color-text-2: 0deg 0% 0%;
    --color-hover: 0deg 0% 100%;
    --color-surface-mixed-100: 0deg 0% 12%;
    --color-surface-mixed-200: 0deg 0% 16%;
    --color-surface-mixed-300: 0deg 0% 30%;
    --color-surface-mixed-400: 0deg 0% 25%;
    --color-surface-mixed-500: 0deg 0% 44%;
    --color-surface-mixed-600: 218deg 6% 61%;
    --color-comp-1: 275deg 95% 92%;
    --color-comp-2: 0deg 0% 40%;
    --color-comp-3: 95deg 95% 92%;
    --color-comp-button-1: 40deg 95% 92%;
  }
  @media (prefers-color-scheme: dark) {
    :root {
      /* --color-primary-100: 211deg 88% 67%; */
      /* --color-primary-100: 212deg 88% 71%; */
      /* --color-primary-100: 213deg 89% 76%; */
      /* --color-primary-100: 214deg 89% 79%; */
      /* --color-primary-100: 215deg 88% 83%; */
      --color-primary-100: 216deg 88% 87%;
      --color-text-1: 0deg 0% 100%;
      --color-text-2: 0deg 0% 0%;
      --color-hover: 0deg 0% 100%;
      --color-surface-mixed-100: 0deg 0% 9%;
      --color-surface-mixed-200: 0deg 0% 16%;
      --color-surface-mixed-300: 0deg 0% 34%;
      --color-surface-mixed-400: 0deg 0% 25%;
      --color-surface-mixed-500: 0deg 0% 44%;
      --color-surface-mixed-600: 218deg 6% 61%;
      --color-comp-1: 275deg 95% 92%;
      --color-comp-2: 0deg 0% 40%;
      --color-comp-3: 95deg 95% 92%;
      --color-comp-button-1: 40deg 95% 92%;
    }
  }
  @media (prefers-color-scheme: light) {
    :root {
      /* --color-primary-100: 211deg 88% 67%; */
      /* --color-primary-100: 212deg 88% 71%; */
      /* --color-primary-100: 213deg 89% 76%; */
      /* --color-primary-100: 214deg 89% 79%; */
      /* --color-primary-100: 215deg 88% 83%; */
      --color-primary-100: 216deg 88% 87%;
      --color-text-1: 0deg 0% 0%;
      --color-text-2: 0deg 0% 0%;
      --color-hover: 0deg 0% 0%;
      --color-surface-mixed-100: 186deg 100% 92%;
      --color-surface-mixed-200: 209deg 100% 86%;
      --color-surface-mixed-300: 209deg 100% 92%;
      --color-surface-mixed-400: 209deg 100% 80%;
      --color-surface-mixed-500: 233deg 100% 92%;
      --color-surface-mixed-600: 210deg 8% 15%;
      --color-comp-1: 275deg 95% 92%;
      --color-comp-2: 209deg 95% 92%;
      --color-comp-3: 95deg 95% 92%;
      --color-comp-button-1: 40deg 95% 92%;
    }
  }
}

.mode-checkbox:checked + .mode-label .mode-ball {
  transform: translateX(23px);
}

.arrow {
  width: 1px;
  height: 1px;
}

/* .editor-shadow {
  box-shadow: 9px 2px 3px 1px rgba(0, 0, 0, 0.1);
} */
